// extracted by mini-css-extract-plugin
export var blogImg = "vC_bq3";
export var blogItem = "vC_bn3";
export var date = "vC_br3";
export var heading = "vC_d3";
export var headingPlaceholderGrid = "vC_bt3";
export var imgWrapper = "vC_bp3";
export var link = "vC_by3";
export var linkPlaceholderGrid = "vC_bw3";
export var placeholder = "vC_bs3";
export var readMore = "vC_bx3";
export var text = "vC_p3";
export var textPlaceholderGrid = "vC_bv3";